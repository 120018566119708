.dateFilterCon{
  width: 160px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  position: relative;
   /* padding-top: 20px; */
   -webkit-touch-callout: none;
   /* iOS Safari */
   -webkit-user-select: none;
   /* Safari */
   -khtml-user-select: none;
   /* Konqueror HTML */
   -moz-user-select: none;
   /* Old versions of Firefox */
   -ms-user-select: none;
   /* Internet Explorer/Edge */
   user-select: none;
   /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.filterCon{
  margin-left: 12px;
  width: 28px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  position: relative;
   /* padding-top: 20px; */
   -webkit-touch-callout: none;
   /* iOS Safari */
   -webkit-user-select: none;
   /* Safari */
   -khtml-user-select: none;
   /* Konqueror HTML */
   -moz-user-select: none;
   /* Old versions of Firefox */
   -ms-user-select: none;
   /* Internet Explorer/Edge */
   user-select: none;
   /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.filterCon:hover,
.dateFilterCon:hover {
  cursor: pointer;
}

.selectedOptionCon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.toggleBtn {
  transition: transform 0.3s;
}

.filterLogoCon{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterCon.open {
  background-color: var(--main-color);
  color: var(--white-color);
}


.dateFilterCon.open .toggleBtn {
  transform: rotate(180deg);
}

.filterOptionsCon {
  display: none;
  width: 240px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  position: absolute;
  top: 38px;
  right: 0;
  background-color: var(--white-color);
  z-index: 1;

}
.filterCon.open .filterOptionsCon,
.dateFilterCon.open .filterOptionsCon {
  display: block;
  
}

.dateOption {
  padding: 10px 10px;
  margin: 10px 0px;
}

.dateOption.selected,
.dateOption:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.datePickerCon {
  display: none;
  padding: 0px 10px
}

.datePickerCon.datePickerOpen {
  display: block;
}
 /* product list filter */
.productsFilterCon {
  margin: 12px 20px;
}

